/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 11:54:05
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-14 22:54:00
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\store\index.js
 * @Description: 
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		name: '',
		authorToken: sessionStorage.getItem('authorToken') || ''
	},
	getters: {
		getToken: (state) => state.authorToken
	},
	actions: {
		setToken: ({ commit }, payload) => {
			sessionStorage.setItem('authorToken', payload)
			commit('updateToken', payload)
		}
	},
	mutations: {
		updateToken: (state, payload) => {
			state.authorToken = payload
		}
	}
})