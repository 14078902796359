/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 15:01:59
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\main.js
 * @Description: 
 */
// =========================================================
// * Vue Material Dashboard - v1.5.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
import ElementUI from 'element-ui'
// import Store from './store'

// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/theme/index.css'
import '@/assets/css/base.scss'
import '@/assets/fonts/index.css'

// router setup
import router from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";
// import VueAnimateNumber from 'vue-animate-number'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";
import store from "./store";

// configure router
// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkExactActiveClass: "nav-item active",
// });

Vue.prototype.$Chartist = Chartist;

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
// Vue.component(VueAnimateNumber)
// Vue.use(VueNumber)
// Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  render: (h) => h(App),
  router,
  data: {
    Chartist: Chartist,
  },
});
