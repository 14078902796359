export const sexEnum = {
	'1': '男',
	'2': '女'
}

// 户主 配偶 父母 子女 家属 亲属 朋友 租客 其他 房东
export const relationEnum = {
	1: '户主',
	2: '配偶',
	3: '父母',
	4: '子女',
	5: '家属',
	6: '亲属',
	7: '朋友',
	8: '租客',
	9: '其他',
	10: '房东',
}

export const lstatusEnum = {
	1: '暂住',
	2: '居住并入住',
	3: '居住未入住',
	4: '常住',
	5: '未入住',
	100: '未知',
}