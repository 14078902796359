<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-21 10:21:27
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\Layout\DashboardLayout.vue
 * @Description: 
-->
<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- <notifications></notifications> -->

    <side-bar :sidebar-item-color="sidebarBackground" :sidebar-background-image="sidebarBackgroundImage" title="智慧社区">
      <mobile-menu slot="content"></mobile-menu>
      <el-menu :default-active="menuActive" :router="true" class="el-menu-vertical" background-color="rgba(0,0,0,0)"
        text-color="#fff" active-text-color="#36AB64" active-background-color="#36AB64">
        <el-submenu :index="menu.name" v-for="(menu, index) in menus" :key="menu.id">
          <template slot="title">
            <span :class="icons[index]"></span>
            <span class="menu-title">{{ menu.title }}</span>
          </template>
          <el-menu-item :index="pathEnum[item.name]" v-for="item in menu.children">{{ item.title }}</el-menu-item>
        </el-submenu>
      </el-menu>
      <!-- <sidebar-link to="/home">
        <md-icon>dashboard</md-icon>
        <p>人口库首页</p>
      </sidebar-link>
      <sidebar-link to="/peopleResult">
        <md-icon>content_paste</md-icon>
        <p>户信息查询结果列表</p>
      </sidebar-link>
      <sidebar-link to="/household">
        <md-icon>person</md-icon>
        <p>户信息管理</p>
      </sidebar-link>
      <sidebar-link to="/serviceProfile">
        <md-icon>content_paste</md-icon>
        <p>服务档案</p>
      </sidebar-link>
      <sidebar-link to="/communityManagement">
        <md-icon>library_books</md-icon>
        <p>小区管理</p>
      </sidebar-link>
      <sidebar-link to="/userAdmin">
        <md-icon>bubble_chart</md-icon>
        <p>用户管理</p>
      </sidebar-link>
      <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link> -->
    </side-bar>

    <div class="main-panel">
      <top-navbar @openUpdatePas="openUpdatePas" @openUserInfo="openUserInfo" :organName="organName"
        :username="username"></top-navbar>
      <!-- 
      <fixed-plugin :color.sync="sidebarBackground" :image.sync="sidebarBackgroundImage">
      </fixed-plugin> -->

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>

    <el-dialog title="个人信息" :visible.sync="userInfoVisible" width="600px">
      <el-descriptions title="" class="user-descriptions" :column="2">
        <el-descriptions-item label="用户名">{{ myInfo.username }}</el-descriptions-item>
        <el-descriptions-item label="姓名">{{ myInfo.realName }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ myInfo.mobile }}</el-descriptions-item>
        <el-descriptions-item label="工作电话">{{ myInfo.phone }}</el-descriptions-item>
        <el-descriptions-item label="工作条线">
          <el-tag v-for="name in myInfoLink" class="mr-5">{{ name || '--' }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          {{ myInfo.note }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userInfoVisible = false" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="updatePasVisible" width="500px">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原始密码" prop="oldPassword">
          <el-input type="password" v-model="ruleForm.oldPassword" placeholder="请输入原始密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" placeholder="请输入新密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" placeholder="请输入确认密码" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="UpdatePasDialogClose">取 消</el-button>
        <el-button type="success" @click="confirmPas">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
// import FixedPlugin from "./Extra/FixedPlugin.vue";
import * as API from '@/api/common.js'
import { getWorkLimitList } from '@/api/userAdmin.js'

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    // FixedPlugin,
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPassword: ''
        // oldPassword newPassword
      },
      rules: {
        pass: [
          { required: true, validator: validatePass, trigger: 'blur', }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
      },
      selectedEmployee: null,
      updatePasVisible: false,
      userInfoVisible: false,
      icons: {
        '0': 'el-icon-s-home',
        '1': 'el-icon-s-tools',
      },
      menuActive: 'home',
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      menus: [],
      pathEnum: {
        'populations': '',
        // 'ums': 'home',
        'populationAll': 'home',
        'house': 'householdList',
        'population': 'peopleResult',
        'file': 'serviceProfile',
        'tags': 'tags',
        'admin': 'userAdmin',
        'area': 'communityManagement',
        'role': 'role',
        'syslog': 'systemLog',
        'grid': 'gridManage'
      },
      organName: '',
      username: '',
      myInfo: {},
      workLimitList: [],
      myInfoLink: []
    };
  },
  created () {
    this.getMenu()
    this.getMyInfo()
    // this.getWorkLimitList()
  },
  watch: {
    '$route': {
      handler: function (nVal) {
        console.info(nVal, 'route naval')
        this.menuActive = nVal.path.replace('/', '')
      },
      immediate: true
    }
  },
  methods: {
    /**
 * 获取工作条线
 */
    async getWorkLimitList (link) {
      try {
        const data = await getWorkLimitList()

        if (data.code === 200) {
          this.workLimitList = data.data || []
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getMyInfo () {
      const data = await API.getMyInfo()

      if (data.code === 200) {
        this.myInfo = data.data.info || {}
        this.myInfoLink = data.data.workLimitNames || []
        // this.getWorkLimitList(data.data.link)
      }
    },
    openUpdatePas () {
      this.updatePasVisible = true
    },
    openUserInfo () {
      this.userInfoVisible = true
    },
    confirmPas () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { pass, oldPassword } = this.ruleForm
          const data = await API.updatePas({
            oldPassword,
            newPassword: pass
          })

          if (data.code === 200) {
            this.$notify.success({
              title: '提示',
              message: '密码修改成功'
            })
            this.UpdatePasDialogClose()
            this.$alert('密码修改成功，请重新登录', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                sessionStorage.removeItem('authorToken')
                window.location.href = '/'
              }
            });
            // setTimeout(() => {
            //   sessionStorage.removeItem('authorToken')
            //   window.location.href = '/'
            // }, 20000)
          }
        }
      });
    },
    UpdatePasDialogClose () {
      this.ruleForm = {
        pass: '',
        checkPass: '',
        oldPassword: ''
      }
      this.$refs.ruleForm.clearValidate()
      this.updatePasVisible = false
    },
    async getMenu () {
      const data = await API.getMenu()
      if (data.code === 200) {
        this.organName = data.data.organName
        this.username = data.data.username
        this.menus = data.data.menus || []
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-title {
  font-size: 18px;
}

::v-deep .el-submenu .el-menu-item {
  font-size: 16px;
}

::v-deep .el-menu-vertical {
  height: calc(100vh - 95px);
  border: none;
}

.user-descriptions {
  font-size: 16px;
}
</style>
