<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-14 16:01:16
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\Layout\ContentFooter.vue
 * @Description: 
-->
<template>
  <footer class="footer">
    <!-- <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://www.creative-tim.com">Creative Tim</a>
          </li>
          <li>
            <a href="https://creative-tim.com/presentation"> About Us </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com"> Blog </a>
          </li>
          <li>
            <a href="https://www.creative-tim.com/license"> Licenses </a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://www.creative-tim.com/?ref=mdf-vuejs" target="_blank"
          >Creative Tim</a
        >, made with <i class="fa fa-heart heart"></i> for a better web
      </div>
    </div> -->
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
