<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-16 11:00:00
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\components\SidebarPlugin\SidebarLink.vue
 * @Description: 
-->
<template>
  <li class="md-list-item" id="md-list">
    <router-link class="md-list-item-router md-list-item-container md-button-clean" @click="hideSidebar" v-bind="$attrs">
      <div class="md-list-item-content md-ripple">
        <slot>
          <md-icon>{{ link.icon }}</md-icon>
          <p>{{ link.name }}</p>
        </slot>
      </div>
    </router-link>
  </li>
</template>
<script>
export default {
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar () {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#md-list {
  ::v-deep .router-link-active {
    background-color: #4caf50 !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(76, 175, 80, .28), 0 4px 20px 0 rgba(0, 0, 0, .12), 0 7px 8px -5px rgba(76, 175, 80, .2);
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, .28), 0 4px 20px 0 rgba(0, 0, 0, .12), 0 7px 8px -5px rgba(76, 175, 80, .2);

    &:hover {
      background-color: #4caf50 !important;
    }
  }
}
</style>
