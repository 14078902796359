<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-17 17:35:47
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2024-01-15 15:39:17
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\household\index.vue
 * @Description: 
-->
<template>
	<div class="content">
		<div class="household-head">
			<div class="household-head-form">
				<div>
					<p class="household-head-form-label">户信息</p>
				</div>
				<el-select class="household-head-form-select" v-model="searchForm.area">
					<el-option v-for="item in communityList" :value="item.code" :label="item.name"
						:key="item.code"></el-option>
				</el-select>
				<!-- <el-input class="household-head-form-input" placeholder="请输入小区" v-model="searchForm.area"></el-input> -->
				<el-input class="household-head-form-input" placeholder="请输入" v-model="searchForm.bding">
					<span slot="suffix" class="suffix-text">
						楼幢
					</span>
				</el-input>
				<el-input class="household-head-form-input" placeholder="请输入" v-model="searchForm.unit">
					<span slot="suffix" class="suffix-text">
						单元
					</span>
				</el-input>
				<el-input class="household-head-form-input" placeholder="请输入" v-model="searchForm.hnum">
					<span slot="suffix" class="suffix-text">
						房号
					</span>
				</el-input>
				<md-button class="md-success btn" @click="getPeople">定位</md-button>
			</div>
			<div class="household-head-btns">
				<md-button class="md-success btn" @click="addPerson"><md-icon class="icon-add">add</md-icon>添加人员</md-button>
				<md-button class="md-success btn" @click="houseVisible = true">本户详情</md-button>
				<md-button class="md-success btn" @click="openServiceProfile">家庭服务档案</md-button>
				<!-- <md-button class="md-success btn">变动记录</md-button> -->
			</div>
		</div>

		<div class="people-container">
			<div class="people-owner mr-20" v-for="item in houseHoldList.buyers" :ke="item.phone">
				<div class="people-owner-icon"><img src="@/assets/img/home/1.png" alt="icon" srcset=""></div>
				<div class="people-owner-label">
					<div class="people-owner-text">{{ item.name }}</div>
					<div class="people-owner-name">业主</div>
				</div>
			</div>
			<div class="people-items">
				<el-tabs v-model="peopleActiveName" @tab-click="selectPerson">
					<el-tab-pane v-for="(item, index) in peopleList" :key="item.id" :name="index + ''">
						<div slot="label" class="people-item">
							<div class="people-item-icon">
								<img :src="item.icon || headSculpture" alt="icon" srcset="">
								<!-- <i v-else class="el-icon-user"></i> -->
							</div>
							<div class="people-owner-label">
								<div class="people-item-text">{{ item.name || '--' }}</div>
								<div class="people-item-name">{{ relationEnum[item.relation] ?
									(relationEnum[item.relation] + ' ' + (item.relationRemark || '')) : '--' }}</div>
							</div>
						</div>
						<!-- <span slot="label"><i class="el-icon-date"></i> 我的行程</span> -->
					</el-tab-pane>
				</el-tabs>
				<!-- <div class="people-item mr-20" v-for="item in peopleList" :key="item.id" @click="selectPerson(item)">
					<div class="people-item-icon"><img src="@/assets/img/home/2.png" alt="icon" srcset=""></div>
					<div class="people-owner-label">
						<div class="people-item-text">成员</div>
						<div class="people-item-name">{{ item.name || '--' }}</div>
					</div>
				</div>  -->
			</div>
		</div>

		<div class="form-container" v-loading="loading">
			<div v-if="peopleList && peopleList.length">
				<div class="form-title md-title">当前用户信息</div>
				<div class="form-operate">
					<div class="form-operate-tag" v-if="trendsForm.relation">{{ relationEnum[trendsForm.relation] }}</div>
					<div class="form-operate-name">{{ trendsForm.name || '--' }}</div>
					<div class="form-operate-btns" v-if="trendsForm.id">
						<md-button class="md-success form-operate-btn" @click="OpenPeopleMove"><img class="btn-icon"
								src="@/assets/img/household/icon-3.png" alt="" srcset="">当前人移户</md-button>
						<md-button class="md-success form-operate-btn" @click="peopleDelete"><img class="btn-icon"
								src="@/assets/img/household/icon-1.png" alt="" srcset="">当前人删除</md-button>
						<!-- <md-button class="md-success form-operate-btn"><img class="btn-icon"
								src="@/assets/img/household/icon-2.png" alt="" srcset="">当前人死亡注销</md-button> -->
					</div>
				</div>

				<div class="form-tabs">
					<div class="tabs-items">
						<div @click="changeTabs(1)" :class="{ active: tabsActive == 1 }" class="tabs-item">基础信息</div>
						<div @click="changeTabs(2)" :class="{ active: tabsActive == 2 }" class="tabs-item">变动记录</div>
						<!-- <div class="tabs-item">计生信息</div>
					<div class="tabs-item">就业信息</div> -->
					</div>
					<div v-if="tabsActive == 1" class="form-info">
						<el-upload class="avatar-uploader" action="/api/population/upload" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
							:headers="{ token: authorToken }">
							<img v-if="trendsForm.pic" :src="trendsForm.pic" class="avatar">
							<div v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div class="upload-pic">照片</div>
							</div>
						</el-upload>
						<!-- <el-upload class="form-upload-img" action="https://jsonplaceholder.typ icode.com/posts/"
							list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
							<i class="el-icon-plus"></i>
						</el-upload> -->
						<div class="ml-10">
							<trends-form ref="trendsForm" :trendsForm="trendsForm" :formItems="formItems"
								@submit="addPersonApi" />

							<div class="people-title mt-20">人员类型</div>
							<el-form ref="peopleForm" class="people-form">
								<el-form-item :label="item.name" v-for="item in formTypeArr" :key="item.name">
									<el-checkbox-group v-model="tags" v-if="item.range.length">
										<el-checkbox v-for="option in item.range" :key="option.code" class="checkbox"
											:label="option.code" border>{{ option.name }}</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</el-form>
							<div class="sub-btn"><md-button class="md-success btn" @click="submitForm">提 交</md-button></div>
						</div>
					</div>
					<div v-else-if="tabsActive == 2" class="change-table">
						<el-table v-if="changeTableData && changeTableData.length" :data="changeTableData">
							<el-table-column type="index" align="center" label="序号" width="100">
							</el-table-column>
							<el-table-column prop="record" label="变更内容" min-width="200">
								<template slot-scope="scope">
									<p>{{ scope.row.record && scope.row.record.join('、') || '--' }}</p>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="操作人" min-width="120">
							</el-table-column>
							<el-table-column prop="tstr" label="操作时间" min-width="120">
							</el-table-column>
						</el-table>
						<el-empty v-else description="暂无数据"></el-empty>
					</div>
				</div>
			</div>
			<div v-else-if="!loading">
				<el-empty description="暂无数据"></el-empty>
			</div>
		</div>
		<!-- 查看家庭服务档案列表 -->
		<el-dialog title="家庭服务档案" :visible.sync="serviceProfileVisible" width="750px">
			<template v-if="serviceProfileTable && serviceProfileTable.length">
				<div class="list-item" v-for="item in serviceProfileTable" :key="item.id">
					<div class="list-item-title">
						<div class="flex-1"><i class="el-icon-s-home mr-5"></i>{{ item.address }}</div>
						<div class="type">服务类型：{{ fwType[item.type] }}</div>
					</div>
					<el-descriptions title="" class="mt-20 ml-20 list-item-info" :column="3">
						<el-descriptions-item label="服务内容" :span="3">{{ item.content }}</el-descriptions-item>
						<el-descriptions-item label="服务对象" :span="3">{{ item.nameList && item.nameList.join('、') || '--'
						}}<span v-if="item.otherName">{{ '、' + item.otherName }}</span></el-descriptions-item>
						<el-descriptions-item label="操作人员">{{ item.operator || '--' }}</el-descriptions-item>
						<el-descriptions-item label="服务时间">{{ item.time || '--' }}</el-descriptions-item>
					</el-descriptions>
				</div>
			</template>
			<el-empty v-else description="暂无数据"></el-empty>
		</el-dialog>
		<!-- 查看本户信息 -->
		<el-dialog title="本户详情" :visible.sync="houseVisible" width="750px">
			<el-descriptions title="" class="house-descriptions" :column="2">
				<el-descriptions-item label="户地址">{{ houseHoldList.address }}</el-descriptions-item>
				<el-descriptions-item label="户性质" :span="2" v-if="houseHoldList.hpropty">{{
					hproptyList[houseHoldList.hpropty -
						1].label || '-'
				}}</el-descriptions-item>
				<!-- <el-descriptions-item label="自定义标签">苏州市</el-descriptions-item> -->
				<el-descriptions-item label="自定义标签" v-if="houseHoldList.tags" :span="2">
					<template v-for="(item, index) in tagsList">
						<el-tag class="mr-5" v-if="houseHoldList.tags.indexOf(item.code) > -1" :key="item.code"
							effect="plain">
							{{ item.name }}
						</el-tag>
					</template>
				</el-descriptions-item>
				<el-descriptions-item label="备注">{{ houseHoldList.hremark || '--' }}</el-descriptions-item>
			</el-descriptions>
			<el-descriptions title="业主信息" class="house-descriptions mt-10" :column="9">
				<template v-for="item in houseHoldList.buyers">
					<el-descriptions-item label="姓名" :span="2">{{ item.name }}</el-descriptions-item>
					<el-descriptions-item label="联系电话" :span="3">{{ item.phone }}</el-descriptions-item>
					<el-descriptions-item label="备注" :span="4">{{ item.remark }}</el-descriptions-item>
				</template>
				<!-- <div v-if="houseHoldList.buyers.length == 0 || !houseHoldList.buyers">--</div> -->
			</el-descriptions>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="houseVisible = false">关闭</el-button>
			</div>
			<!-- <el-descriptions class="house-descriptions" :column="3" title="">
				<el-descriptions-item label="姓名">{{ houseHoldList.hremark }}</el-descriptions-item>
			</el-descriptions> -->
		</el-dialog>

		<!-- 当前人员移户操作 -->
		<el-dialog title="当前人移户" :visible.sync="peopleMoveVisible" :before-close="peopleHandleClose" width="750px">
			<el-form ref="peopleMoveForm" :rules="peopleFormRules" label-width="80px" :model="peopleMoveForm" inline>
				<el-form-item label="原住址" prop="username">
					<el-input :value="currentCommunityName(houseHoldList.area)" disabled class=" mr-10"
						style="width: 120px;"></el-input>
					<!-- <el-select class="mr-10" :value="houseHoldList.area" disabled style="width: 120px;">
						<el-option v-for="item in communityList" :value="item.code" :label="item.name"
							:key="item.code"></el-option>
					</el-select> -->
					<el-input :value="houseHoldList.bding" disabled class=" mr-10" style="width: 100px;" placeholder="请输入">
						<span slot="suffix" class="suffix-text">
							楼幢
						</span>
					</el-input>
					<el-input :value="houseHoldList.unit" disabled class=" mr-10" style="width: 100px;" placeholder="请输入">
						<span slot="suffix" class="suffix-text">
							单元
						</span>
					</el-input>
					<el-input :value="houseHoldList.hnum" disabled class="" style="width: 100px;" placeholder="请输入">
						<span slot="suffix" class="suffix-text">
							房号
						</span>
					</el-input>
				</el-form-item>
				<el-form-item label="移动到">
					<el-form-item label="" prop="area">
						<el-select class="" v-model="peopleMoveForm.area" style="width: 120px;">
							<el-option v-for="item in communityList" :value="item.code" :label="item.name"
								:key="item.code"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="bding">
						<el-input v-model="peopleMoveForm.bding" class="" style="width: 100px;" placeholder="请输入">
							<span slot="suffix" class="suffix-text">
								楼幢
							</span>
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="unit">
						<el-input v-model="peopleMoveForm.unit" class="" style="width: 100px;" placeholder="请输入">
							<span slot="suffix" class="suffix-text">
								单元
							</span>
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="hnum">
						<el-input v-model="peopleMoveForm.hnum" class="" style="width: 100px;" placeholder="请输入">
							<span slot="suffix" class="suffix-text">
								房号
							</span>
						</el-input>
					</el-form-item>
					<md-button class="md-success query-btn" @click="peopleMove"><i
							class="el-icon-zoom-in el-icon mr-5"></i>查
						询</md-button>
				</el-form-item>
				<el-form-item label="住 址">
					<el-select v-model="peopleMoveForm.hid" placeholder="请选择住址" class="auth-select">
						<el-option v-for="item in hList" :key="item.id" :value="item.id" :label="item.address"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="peopleHandleClose">取 消</el-button>
				<el-button type="success" @click="moveHouse" :disabled="!peopleMoveForm.hid">确 定</el-button>
				<!-- <md-button @click="peopleMoveVisible = false">取 消</md-button>
				<md-button class="md-success" @click="peopleMove">确 认</md-button> -->
			</div>
		</el-dialog>
		<!-- 当前人删除 -->
		<el-dialog title="当前人删除" :visible.sync="popleDeleteVisible" width="400px" :before-close="peopleDeleteClose">
			<el-form ref="peopleDeleteForm" :rules="popleDeleteFormRules" label-width="80px" :model="popleDeleteForm">
				<el-form-item label="删除原因" prop="type">
					<el-select class="" v-model="popleDeleteForm.type">
						<el-option value="1" label="搬离"></el-option>
						<el-option value="2" label="离世"></el-option>
						<el-option value="3" label="信息有误"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="peopleDeleteClose">取 消</el-button>
				<el-button type="success" @click="peopleDeleteSub">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import TrendsForm from '@/components/trendsForm/index.vue'
import { formItems, formTypeArr } from './form.js'
import * as API from '@/api/household.js'
import { getTags } from '@/api/peopleResult.js'
import { getFamilyRecord } from '@/api/serviceProfile.js'
import { getCommunity } from '@/api/communityManagement.js'
import { relationEnum } from '@/enum/index.js'
import { getHouseList } from '@/api/peopleResult.js'
import UserHeadImg from '@/assets/img/household/default.png'

const hjFormItem = [
	{
		label: '迁入时间',
		key: 'qrTime',
		type: 'date',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择', trigger: 'change' },
		],
	},
	{
		label: '原户籍所在地',
		key: 'yhjAddress',
		type: 'input',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入', trigger: 'blur' },
		],
	},
]
export default {
	components: {
		TrendsForm,
		// VueAnimateNumber
	},
	data () {
		return {
			headSculpture: UserHeadImg,
			hproptyList: '常住户籍人口房=常住非户籍人口房=户籍在人不在（出租房）=人户不在（出租房）=户籍在人不在（群租房）=人户不在（群租房）=户籍在人不在（空置房）=人户不在（空置房）'.split('=').map((v, i) => {
				return {
					label: v,
					value: i + 1
				}
			}),
			fwType: {
				1: '大厅服务',
				2: '走访记录',
				3: '社区活动',
			},
			popleDeleteForm: {
				type: ''
			},
			popleDeleteFormRules: {
				type: [
					{
						required: true,
						message: ' 请选择删除原因',
						trigger: 'change'
					}
				],
			},
			peopleFormRules: {
				area: [
					{
						required: true,
						message: ' 请选择小区',
						trigger: 'change'
					}
				],
				bding: [
					{
						required: true,
						message: ' 请输入楼幢',
						trigger: 'blur'
					}
				],
				hnum: [
					{
						required: true,
						message: ' 请输入房号',
						trigger: 'blur'
					}
				],
				unit: [
					{
						required: true,
						message: ' 请输入单元',
						trigger: 'blur'
					}
				],
			},
			serviceProfileTable: [],
			serviceProfileVisible: false,
			peopleMoveVisible: false,
			houseVisible: false,
			popleDeleteVisible: false,
			peopleMoveForm: {
				area: '',
				bding: '',
				hnum: '',
				unit: '',
				hid: ''
			},
			peopleActiveName: '0',
			tabsActive: '1',
			changeTableData: [],
			authorToken: sessionStorage.authorToken,
			relationEnum: relationEnum,
			formItems: formItems || [],
			formTypeArr: JSON.parse(JSON.stringify(formTypeArr)),
			trendsForm: {
				name: '',
				idcard: '',
				sex: 3,
				birthDay: '',
				nation: 100,
				nplace: '',
				pstatus: 100,
				health: 1,
				isAlone: 100,
				marryStatus: 100,
				educate: 100,
				byStatus: 100,
				phone: '',
				otherLink: '',
				// qq: '',
				carNumber: '',
				remark: '',
				relation: '',
				lstatus: 100,
				hjry: '',
				qrTime: '',
				yhjAddress: '',
				hjaddress: '',
				address: '',
				workUnit: '',
				job: '',
				workStatus: 100,
				isHuji: '',
				relationRemark: '',
				pic: '',
				// records: []
			},
			searchForm: {
				area: '',
				bding: '',
				hnum: '',
				unit: ''
			},
			peopleForm: {
				dz: ''
			},
			peopleList: [],
			communityList: [],
			hid: '',
			tags: [],
			houseHoldList: [],
			loading: true,
			tagsList: [],
			hList: [],
			rid: ''
		}
	},
	created () {
		const { hid, rid } = this.$route.query
		this.hid = hid
		this.rid = rid
		if (hid) this.getPeopleList()

		// this.getPeople()
		this.getCommunity()
		this.getTags()
		this.getFamilyRecord()
		this.getHouseTags()
	},
	watch: {
		'trendsForm.health': function (nVal) {
			console.info(nVal, 'health')
			if (nVal === 2) {
				this.tags.push('CJR')
			} else {
				const index = this.tags.findIndex(v => v === 'CJR')
				if (index > -1) {
					this.tags.splice(index, 1)
				}
			}
		},
		'trendsForm.lstatus': function (nVal) {
			if (nVal === 1) {
				this.tags.push('ZZRY1')
			} else {
				const index = this.tags.findIndex(v => v === 'ZZRY1')
				// this.tags.splice(index, 1)
				if (index > -1) {
					this.tags.splice(index, 1)
				}
			}
			// console.info('watch trendsForm.lstatus', nVal, this.trendsForm.isHuji, this.formItems[21].key)
			if ((nVal == 2 || nVal == 3) && this.formItems[21].key != 'qrTime') {
				// this.hjFormItem
				this.trendsForm.isHuji = 1
				this.trendsForm.hjaddress = this.trendsForm.hjaddress || this.houseHoldList.address
				this.trendsForm.address = this.trendsForm.address || this.houseHoldList.address
				this.formItems.splice(21, 0, hjFormItem[0])
				this.formItems.splice(22, 0, hjFormItem[1])
			} else if (this.formItems[21].key == 'qrTime' && nVal != 2 && nVal != 3) {
				this.trendsForm.isHuji = 2
				this.formItems.splice(21, 2)
				// this.formItems.splice(21, 0, hjFormItem[1])
			}
		},
		// 'trendsForm.isHuji': function (nVal) {
		// 	console.info('watch trendsForm.isHuji', nVal, this.trendsForm.lstatus, this.formItems[21].key)
		// 	if ((this.trendsForm.lstatus == 2 || this.trendsForm.lstatus == 3) && nVal == 1 && this.formItems[21].key != 'qrTime') {
		// 		this.formItems.splice(21, 0, hjFormItem[0])
		// 		this.formItems.splice(22, 0, hjFormItem[1])
		// 	} else if (this.formItems[21].key == 'qrTime' && nVal != 1) {
		// 		this.formItems.splice(21, 2)
		// 	}
		// },
		'trendsForm.workStatus': function (nVal) {
			const codeEnum = {
				2: 'SYRY',
				6: 'LHJYRY',
				11: 'QTXSJY',
			}
			if (nVal === 2 || nVal === 6 || nVal === 11) {
				this.tags.push(codeEnum[nVal])
			} else {
				const index = this.tags.findIndex(v => v === codeEnum[nVal])
				// this.tags.splice(index, 1)
				if (index > -1) {
					this.tags.splice(index, 1)
				}
			}
		},
		'trendsForm.idcard': function (nVal) {
			if (/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(nVal)) {
				this.getCardInfo(nVal)
			}
		}
	},
	methods: {
		// communityList
		currentCommunityName (communityCode) {
			let communityName = ''
			this.communityList.forEach(v => {
				if (v.code == communityCode) {
					communityName = v.name
				}
			})
			return communityName
		},
		peopleDelete () {
			this.popleDeleteVisible = true
		},
		peopleDeleteClose () {
			this.popleDeleteVisible = false
			this.popleDeleteForm.type = ''
			this.$nextTick(() => {
				this.$refs.peopleDeleteForm.clearValidate()
			})
		},
		peopleDeleteSub () {
			this.$refs.peopleDeleteForm.validate(async valid => {
				if (valid) {
					const data = await API.delPerson({
						id: this.trendsForm.id,
						delReason: this.popleDeleteForm.type - 0
					})

					if (data.code === 200) {
						this.$notify.success({
							title: '提示',
							message: '删除成功'
						})
						this.peopleDeleteClose()
						this.getPeopleList()
					}
				}
			})
		},
		peopleHandleClose () {
			this.peopleMoveForm = {
				area: '',
				bding: '',
				hnum: '',
				unit: '',
				hid: ''
			}
			this.hList = []
			this.peopleMoveVisible = false
			this.$nextTick(() => {
				this.$refs.peopleMoveForm.clearValidate()
			})
		},
		peopleMove () {
			this.$refs.peopleMoveForm.validate(async valid => {
				if (valid) {
					const data = await getHouseList({
						...this.peopleMoveForm
					})

					if (data.code === 200) {
						this.hList = data.data.list || []
						this.$notify.success({
							title: '提示',
							message: '查询成功, 请选择住址'
						})
						// const hid = data.data.list[0].id
						// this.moveHouse(hid)
					}
				}
			})
		},
		async moveHouse () {
			const data = await API.moveHouse({
				oldHid: this.hid,
				newHid: this.peopleMoveForm.hid,
				pid: this.trendsForm.id
			})
			if (data.code === 200) {
				this.$notify.success({
					title: '提示',
					message: '移户成功'
				})
				this.peopleHandleClose()
				this.getPeopleList()
			}
		},
		OpenPeopleMove () {
			this.peopleMoveVisible = true
		},
		changeTabs (v) {
			this.tabsActive = v
		},
		openServiceProfile () {
			this.serviceProfileVisible = true
		},
		async getFamilyRecord () {
			const data = await getFamilyRecord({
				hid: this.hid
			})
			if (data.code === 200) {
				this.serviceProfileTable = data.data.list || []
			}
		},
		submitForm () {
			console.info(this.$refs.trendsForm.submitForm, 'this.$refs.trendsForm')
			this.$refs.trendsForm.submitForm()
		},
		async getCardInfo (idCard) {
			const data = await API.getCardInfo({
				idCard
			})
			if (data.code === 200) {
				this.trendsForm.sex = data.data.sex
				this.trendsForm.birthDay = data.data.birthDay
				this.trendsForm.nplace = data.data.nplace
			}
		},
		async getPeopleList () {
			this.loading = true
			const data = await API.getPeopleList({
				hid: this.hid
			})
			this.loading = false
			if (data.code === 200) {
				this.peopleList = data.data.populationList || []
				this.houseHoldList = data.data.houseHold || []
				Object.keys(this.searchForm).forEach(key => {
					if (this.houseHoldList[key]) {
						this.searchForm[key] = this.houseHoldList[key]
					}
				})
				if (this.rid) {
					const rIndex = this.peopleList.findIndex(i => i.id === this.rid)
					rIndex && (this.peopleActiveName = rIndex + '')
					this.rid = null
				}
				if (this.peopleList[this.peopleActiveName - 0]) {
					this.selectPerson(this.peopleActiveName)
					// this.peopleActiveName = peopleActiveName
					// const peopleInfo = this.peopleList[0]
					// Object.keys(this.trendsForm).forEach(key => {
					// 	if (peopleInfo[key]) {
					// 		this.trendsForm[key] = peopleInfo[key]
					// 	}
					// })
					// this.trendsForm.id = peopleInfo.id
					// this.tags = peopleInfo.tags || []
				}
			}
		},
		selectPerson (i) {
			// const peopleInfo = this.peopleList[0]
			// this.peopleList[0]
			console.info(i, 'selectPerson')
			const index = i.index != undefined ? (i.index - 0) : (i - 0)
			const peopleInfo = this.peopleList[index]
			Object.keys(this.trendsForm).forEach(key => {
				// if (peopleInfo[key]) {
				this.trendsForm[key] = peopleInfo[key] || ''
				// }
			})
			if (peopleInfo.ioList && peopleInfo.ioList[0]) {
				this.trendsForm.qrTime = peopleInfo.ioList[0].tstr || ''
			}
			// ioList
			console.info(peopleInfo.tagList, 'peopleInfo.tagList')
			// this.peopleActiveName = i.index != undefined
			this.trendsForm.id = peopleInfo.id
			this.tags = peopleInfo.tagList ? [...peopleInfo.tagList] : []
			this.changeTableData = peopleInfo.records || []
		},
		async getPeople () {
			this.loading = true
			const data = await API.getPeople({
				...this.searchForm
			})
			this.loading = false
			if (data.code === 200) {
				this.peopleList = data.data.populationList || []
				this.houseHoldList = data.data.houseHold || []
				Object.keys(this.searchForm).forEach(key => {
					if (this.houseHoldList[key]) {
						this.searchForm[key] = this.houseHoldList[key]
					}
				})
				if (this.peopleList[0]) {
					this.selectPerson('0')
				}
				this.peopleActiveName = '0'
				// this.peopleList = data.data || []
			}
		},
		async getTags () {
			const data = await API.getTags()

			if (data.code === 200) {
				const tags = data.data || []
				tags.forEach(item => {
					const index = item.ptype == 0 ? 7 : (item.ptype - 1)
					this.formTypeArr[index].range.push(item)
				})
				// this.tagsList = tags
				// const tags = data.data.filter(v => v.ptype == 0)
				// const leng = this.formTypeArr.length
				// this.formTypeArr[leng - 1].range = tags
			}
		},
		async getHouseTags () {
			const data = await getTags()

			if (data.code === 200) {
				this.tagsList = data.data || []
			}
		},
		async addPersonApi (valid) {
			if (valid) {
				// const params = {}
				// this.formItems.forEach(item => {
				// 	params[item.key] = item.value
				// })
				this.loading = true
				const data = await API.savePerson({
					...this.trendsForm,
					hid: this.hid,
					ioList: [
						{
							type: 1,
							tstr: this.trendsForm.qrTime,
							remark: ''
						}
					],
					tagList: this.tags
				})
				this.loading = false
				if (data.code === 200) {
					const message = this.trendsForm.id ? '人员编辑成功' : '人员添加成功'
					this.$notify.success({
						title: '提示',
						message
					})
					this.getPeopleList()
				}
			}
		},
		handleAvatarSuccess (res, file) {
			if (res.code === 200) {
				this.trendsForm.pic = res.data.link || '' //'http://39.98.42.30:9000/public/' + res.data
			}
			// this.trendsForm.pic = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload (file) {
			const isJPG = file.type === 'image/jpeg';
			const isPNG = file.type === 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG && !isPNG) {
				this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return (isJPG || isPNG) && isLt2M;
		},
		async getCommunity () {
			const data = await getCommunity()
			if (data.code === 200) {
				this.communityList = data.data || []
			}
		},
		handleRemove (file, fileList) {
			console.log(file, fileList);
		},
		handlePictureCardPreview (file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		addPerson () {
			this.trendsForm = {
				name: '',
				idcard: '',
				sex: 3,
				birthDay: '',
				nation: 100,
				nplace: '',
				pstatus: 100,
				health: 1,
				isAlone: 100,
				marryStatus: 100,
				educate: 100,
				byStatus: 100,
				phone: '',
				otherLink: '',
				// qq: '',
				carNumber: '',
				remark: '',
				relation: '',
				lstatus: 100,
				hjry: '',
				qrTime: '',
				yhjAddress: '',
				hjaddress: '',
				address: '',
				workUnit: '',
				job: '',
				workStatus: 100,
				isHuji: '',
				relationRemark: '',
				pic: '',
				// records: []
			}
			this.tags = []
			this.$refs.trendsForm && this.$refs.trendsForm.resetForm()
			this.peopleList.push({
				...this.trendsForm
			})
			this.peopleActiveName = this.peopleList.length - 1 + ''
			this.changeTableData = []
		}
	}
}
</script>

<style lang="scss" scoped>
.household-head {
	display: flex;
	padding: 10px 20px;
	background-color: #fff;
	font-size: 16px;

	&-form {
		display: flex;
		align-items: center;
		flex: 1;

		&-input {
			margin-left: 10px;
			max-width: 100px;
		}

		&-select {
			// margin-left: 10px;
			min-width: 100px;
		}

		&-label {
			margin: 0;
			width: 60px;
			font-size: 16px;
		}
	}

	.btn {
		margin-left: 15px;
	}
}

.people-container {
	display: flex;
	margin-top: 20px;
	font-size: 16px;

	// background-color: #fff;
	.people-owner {
		margin-right: 15px;
		padding: 12px 25px;
		display: flex;
		background-color: #fff;
		align-items: center;
	}

	.people-items {
		display: flex;
		padding: 12px 0px 0px 0;
		// margin-left: 20px;
		background-color: #fff;

		.people-item {
			padding: 0 15px 0;
			align-items: center;
			display: flex;
			transition: .3;

			&:hover {
				opacity: .7;
				// background-color: #f1f1f1;
				cursor: pointer;
			}
		}

		::v-deep .el-tabs__item {
			padding: 0;
			height: 70px;
			line-height: 20px;
		}

		::v-deep .el-tabs__header {
			margin: 0;
		}
	}

	.people-owner-label {
		margin-left: 12px;
	}
}

.people-owner-icon,
.people-item-icon {
	img {
		width: 48px;
		height: 52px;
	}

	.el-icon-user {
		color: #999;
		font-size: 50px;
	}
}

.form-container {
	padding: 20px;
	margin-top: 20px;
	min-height: 400px;
	background-color: #fff;

	.form-operate {
		margin-top: 10px;
		display: flex;
		padding: 0 20px;
		height: 74px;
		// line-height: 74px;
		align-items: center;
		background: #F5F6FB;

		&-tag {
			width: 68px;
			height: 20px;
			border-radius: 10px;
			opacity: 1;
			font-size: 14px;
			text-align: center;
			color: #fff;
			/* 绿色纯色填充 */
			background: #36CC86;
		}

		&-name {
			margin-left: 8px;
			font-size: 18px;
			flex: 1;
		}

		&-btns {
			.form-operate-btn {
				margin-right: 12px;

				.btn-icon {
					margin-right: 8px;
					width: 20px;
				}
			}
		}
	}

	.form-tabs {
		// display: flex;
		margin-top: 25px;
		background: #fff;

		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);

		.tabs-items {
			display: flex;
			// padding: 9px 0;
			border-bottom: 2px solid #36AB64;

			.tabs-item {
				position: relative;
				width: 130px;
				height: 50px;
				line-height: 50px;
				color: rgba(105, 111, 117, 0.5);
				font-family: Source Han Sans;
				font-size: 20px;
				font-weight: 500;
				text-align: center;
				// border-right: 1px solid #D8D8D8;
				cursor: pointer;
				transition: .3s;

				&::after {
					content: '';
					position: absolute;
					right: 0px;
					top: 7px;
					width: 1px;
					height: 36px;
					background-color: #D8D8D8;
				}

				&:hover {
					opacity: .7;
				}
			}

			.active {
				color: #fff;
				background: #36AB64;

				&::after {
					content: '';
					height: 0;
				}
			}
		}

		.form-info {
			display: flex;
			padding: 25px 0px 25px 20px;
			background: #F5F6FB;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
		}

		// ::v-deep .el-upload--picture-card {
		// 	width: 120px;
		// }

		// .form-upload-img {
		// 	// margin-right: 20px;
		// 	width: 200px;
		// }
		.people-title {
			padding-left: 8px;
			border-left: 3px solid #36AB64;
			font-size: 20px;
			font-weight: 500;
			color: #000000;
		}

		.people-form {
			margin-top: 20px;
		}
	}

	.ml-20 {
		margin-left: 20px;
	}

	.ml-10 {
		margin-left: 10px;
	}

	.checkbox {
		background-color: #fff;
	}

	.sub-btn {
		text-align: right;

		.btn {
			width: 140px;
			font-size: 18px;
		}
	}

	::v-deep .el-checkbox {
		margin: 0px 10px 10px 5px;
	}

	::v-deep .el-form-item__label {
		font-size: 16px;
	}
}

.change-table {
	margin-top: 30px;
	padding: 0 15px;
}

.icon-add {
	position: relative;
	top: 1px;
}

::v-deep .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.upload-pic {
	position: relative;
	top: -70px;
	color: #999;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 160px;
	height: 180px;
	line-height: 180px;
	text-align: center;
}

.avatar {
	max-width: 160px;
	width: 160px;
	height: 180px;
	display: block;
}

.suffix-text {
	line-height: 40px;
}

.house-descriptions {
	font-size: 16px;
}

.list-item {
	margin-bottom: 20px;
	padding-bottom: 20px;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;

	.flex-1 {
		flex: 1;
	}

	&-title {
		display: flex;
		padding: 0 20px;
		background: #36AB64;

		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
		height: 46px;
		line-height: 46px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
	}

	.type {
		font-size: 16px;
	}

	&-info {
		font-size: 16px;
	}
}

.query-btn {
	margin: 0 !important;
	font-size: 16px;
}
</style>