/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-27 20:56:15
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 15:46:35
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\household.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const savePerson = (params) => {
	return Http({
		url: '/population/savePerson',
		method: 'post',
		data: params
	})
}

export const getPeople = (params) => {
	return Http({
		url: '/population/familyList',
		method: 'post',
		data: params
	})
}

export const getTags = () => {
	return Http({
		url: '/population/findTagsByType',
		params: { type: 2 },
		method: 'get'
	})
}

export const getPeopleList = (params) => {
	return Http({
		url: '/population/familyListById',
		method: 'get',
		params
	})
}

export const getCardInfo = (params) => {
	return Http({
		url: '/population/idCardResult',
		method: 'get',
		params
	})
}

export const moveHouse = (params) => {
	return Http({
		url: '/population/moveHouse',
		method: 'post',
		data: params
	})
}

export const delPerson = (params) => {
	return Http({
		url: '/population/delPerson',
		method: 'post',
		data: params
	})
}
// admin/info