<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-28 17:17:36
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\Dashboard.vue
 * @Description: 
-->
<template>
  <div class="content">
    <div class="md-layout layout" v-loading="loading">
      <div class="card-container">
        <div class="card-item">
          <div class="card-item-name">走访量</div>
          <div class="card-item-num">20</div>
          <div class="card-item-yt">环比30% <i class="el-icon-caret-top icon-top"></i></div>
        </div>
        <div class="card-item">
          <div class="card-item-name">更新数据</div>
          <div class="card-item-num">30</div>
          <div class="card-item-yt">环比30% <i class="el-icon-caret-top icon-top"></i></div>
        </div>
        <div class="card-item">
          <div class="card-item-name">待处理问题</div>
          <div class="card-item-num">20</div>
          <div class="card-item-yt">环比30% <i class="el-icon-caret-top icon-top"></i></div>
        </div>
        <div class="card-item">
          <div class="card-item-name">自定义</div>
          <div class="card-item-num">20</div>
          <div class="card-item-yt">环比30% <i class="el-icon-caret-top icon-top"></i></div>
        </div>
      </div>
      <div class="head">
        <!-- <div class="head-community"> -->
        <el-tabs class="head-community" v-model="activeName">
          <el-tab-pane class="head-community-item" label="全 部" name="first"></el-tab-pane>
          <el-tab-pane v-for="item in xqList" :key="item.code" class="head-community-item" :label="item.name"
            :name="item.code"></el-tab-pane>
          <!-- <el-tab-pane class="head-community-item" label="忻州小区" name="third"></el-tab-pane> -->
        </el-tabs>
        <div class="head-total">
          总人口数
          <animate-number v-if="(homeInfo.allNums || homeInfo.allNums == 0) && !loading" class="num" :from="0"
            :to="homeInfo.allNums"></animate-number>
          <span class="num" v-else>0</span>
          人
        </div>
      </div>

      <div class="data-info">
        <!-- <el-popover placement="bottom" width="400" trigger="hover" v-for="(item, index) of infos" :key="item.label"> -->
        <!-- <div>
            <md-button class="md-primary">常驻户籍人口</md-button>
            <md-button class="md-primary">常驻非户籍人口</md-button>
          </div> -->
        <!-- slot="reference" -->
        <div class="data-info-item" v-for="(item, index) of infos" :key="item.label">
          <div class="icon"><img :src="getImgUrl(index + 2)" alt="" srcset=""></div>
          <div class="data-info-item-text ml-15">
            <div class="item-label">{{ item.label }}</div>
            <div class="item-num">
              <animate-number v-if="(homeInfo[item.key] || homeInfo[item.key] == 0) && !loading" :from="0"
                :to="homeInfo[item.key]"></animate-number>
              <span v-else>0</span>
            </div>
          </div>
        </div>
        <!-- </el-popover> -->
      </div>

      <div class="search-container">
        <div class="search-people">
          <div class="search-form">
            <span class="search-form-text search-form-title md-title">找 人</span><el-input size="small"
              class="search-input" v-model="peopleSearchForm.name" placeholder="请输入姓名"></el-input><span
              class="search-form-text">和</span>
            <el-input size="small" class="search-input" v-model="peopleSearchForm.idCard"
              placeholder="请输入身份证号码"></el-input><span class="search-form-text">和</span>
            <el-input size="small" class="search-input" v-model="peopleSearchForm.mobile"
              placeholder="请输入手机号"></el-input><span class="search-form-text">和</span>
            <el-input size="small" class="search-input" v-model="peopleSearchForm.carNumber"
              placeholder="请输入车牌号"></el-input>
          </div>
          <div class="search-btns">
            <md-button class="query-btn md-success" @click="peopleSearch">
              <i class="el-icon-zoom-in el-icon"></i>
              查 询
            </md-button>
            <md-button class="rest-btn md-default" @click="clearPeopleSearch">
              <i class="el-icon-refresh-right el-icon"></i>
              重 置
            </md-button>
          </div>
        </div>

        <div class="search-people search-household">
          <div class="search-form">
            <span class="search-form-text md-title search-form-title">找 户</span>
            <el-select class="search-household-input" v-model="householdSearchForm.area" size="small">
              <el-option v-for="(item, index) in xqList" :key="index" :value="item.code" :label="item.name"></el-option>
            </el-select>
            <el-input class="search-input search-household-input" size="small" v-model="householdSearchForm.bding"
              placeholder="请输入楼幢"></el-input>
            <el-input class="search-input search-household-input" size="small" v-model="householdSearchForm.unit"
              placeholder="请输入单元号"></el-input>
            <el-input class="search-input search-household-input" size="small" v-model="householdSearchForm.hnum"
              placeholder="请输入房间号"></el-input>
          </div>
          <div class="search-btns">
            <md-button class="query-btn md-success" @click="householdSearch">
              <i class="el-icon-zoom-in el-icon"></i>
              查 询
            </md-button>
            <md-button class="rest-btn md-default" @click="clearHouseholdSearch">
              <i class="el-icon-refresh-right el-icon"></i>
              重 置
            </md-button>
          </div>
        </div>
      </div>

      <!-- <div class="menu">
        <div class="menu-item">
          <img class="menu-item-icon" src="@/assets/img/home/icon-1.png" alt="icon" srcset="">
          <span class="menu-item-name">添加人员</span>
        </div>
        <div class="menu-item">
          <img class="menu-item-icon" src="@/assets/img/home/icon-2.png" alt="icon" srcset="">
          <span class="menu-item-name">户管理</span>
        </div>
        <div class="menu-item">
          <img class="menu-item-icon" src="@/assets/img/home/icon-3.png" alt="icon" srcset="">
          <span class="menu-item-name">服务档案</span>
        </div>
        <div class="menu-item">
          <img class="menu-item-icon" src="@/assets/img/home/icon-4.png" alt="icon" srcset="">
          <span class="menu-item-name">统计数据</span>
        </div>
        <div class="menu-item">
          <img class="menu-item-icon" src="@/assets/img/home/icon-5.png" alt="icon" srcset="">
          <span class="menu-item-name">数据管理</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import VueAnimateNumber from 'vue-animate-number';
import {
  StatsCard,
  ChartCard,
  NavTabsCard,
  NavTabsTable,
  OrderedTable,
} from "@/components"
import * as API from '@/api/home.js'
import { getCommunity } from '@/api/communityManagement.js'

export default {
  components: {
    StatsCard,
    ChartCard,
    NavTabsCard,
    NavTabsTable,
    OrderedTable,
    // VueAnimateNumber
  },
  data () {
    return {
      peopleSearchForm: {
        carNumber: '',
        idCard: '',
        mobile: '',
        name: ''
      },
      householdSearchForm: {
        area: '',
        bding: '',
        hnum: '',
        unit: ''
      },
      // infoNums: {
      //   allNums: 0,
      //   hjNums: 0,
      //   czNums: 0,
      //   partyNums: 0,
      //   helpNums: 0,
      //   jsNums: 0,
      //   oldNums: 0,
      //   jmNums: 0,
      //   flowNums: 0,
      //   zzNums: 0,
      //   zdyNums: 0,
      //   houseNums: 0,
      //   shopNums: 0,
      // },
      infos: [
        // {
        //   label: '总人口',
        //   key: '',
        //   value: 0
        // },
        {
          label: '户籍人口',
          key: 'hjNums',
          value: 0
        },
        {
          label: '常住人口',
          key: 'czNums',
          value: 0
        },
        {
          label: '党员人口',
          key: 'partyNums',
          value: 0
        },
        {
          label: '帮扶优抚',
          key: 'helpNums',
          value: 0
        },
        {
          label: '计生人口',
          key: 'jsNums',
          value: 0
        },
        {
          label: '老龄人口',
          key: 'oldNums',
          value: 0
        },
        {
          label: '居民代表',
          key: 'jmNums',
          value: 0
        },
        {
          label: '流动人口',
          key: 'flowNums',
          value: 0
        },
        {
          label: '综治人口',
          key: 'zzNums',
          value: 0
        },
        {
          label: '人员自定义',
          key: 'zdyNums',
          value: 0
        },
        {
          label: '住房',
          key: 'houseNums',
          value: 0
        },
        {
          label: '商铺',
          key: 'shopNums',
          value: 0
        },
      ],
      homeInfo: {
        allNums: null,
        hjNums: null,
        czNums: null,
        partyNums: null,
        helpNums: null,
        jsNums: null,
        oldNums: null,
        jmNums: null,
        flowNums: null,
        zzNums: null,
        zdyNums: null,
        houseNums: null,
        shopNums: null,
      },
      activeName: 'first',
      xqList: [],
      loading: false,
    };
  },
  created () {
    this.getHomeInfo()
    this.getCommunity()
  },
  watch: {
    'activeName': function () {
      this.getHomeInfo()
    }
  },
  methods: {
    async getCommunity () {
      try {
        const data = await getCommunity()
        if (data.code === 200 && data.data) {
          this.xqList = data.data || []
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getHomeInfo () {
      this.loading = true
      const data = await API.getHomeInfo({
        area: this.activeName === 'first' ? '' : this.activeName
      })
      this.loading = false
      if (data.code === 200) {
        this.homeInfo = data.data || {}
      }
    },
    getImgUrl (i) {
      const icon = require(`@/assets/img/home/ic-${i}.png`)
      return icon
    },
    peopleSearch () {
      this.$router.push({
        path: '/peopleResult',
        query: {
          ...this.peopleSearchForm
        }
      })
    },
    householdSearch () {
      this.$router.push({
        path: '/householdList',
        query: {
          ...this.householdSearchForm
        }
      })
    },
    clearPeopleSearch () {
      this.peopleSearchForm = {
        carNumber: '',
        idCard: '',
        mobile: '',
        name: ''
      }
    },
    clearHouseholdSearch () {
      this.householdSearchForm = {
        area: '',
        bding: '',
        hnum: '',
        unit: ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.layout {
  background-color: #fff;
  padding: 30px 0;

  .card-container {
    display: flex;
    padding: 0 20px 0 30px;
    width: 100%;

    .card-item {
      margin-right: 10px;
      padding: 8px 0 15px;
      flex: 1;
      border: 1px solid #E5E8EF;
      border-radius: 5px;
      text-align: center;

      &-name {
        margin-top: 5px;
        font-size: 24px;
        color: #8E9094;
      }

      &-num {
        font-size: 50px;
        line-height: 60px;
        color: #233128;
      }

      &-yt {
        margin: 0 auto;
        width: 133px;
        height: 32px;
        line-height: 32px;
        border-radius: 10px;
        background: #E5E8EF;
        font-size: 18px;
        color: #8E9094;
      }

      .icon-top {
        color: #FF7373;
        font-size: 18px;
      }
    }
  }

  .head {
    margin: 20px 30px 0;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #36AB64;
    font-size: 20px;

    ::v-deep .el-tabs__header {
      margin: 0px;
    }

    .head-community {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0 1px;
      height: 66px;

      // &-item {
      //   padding: 0 30px;
      //   height: 63px;
      //   line-height: 63px;
      //   color: #fff;
      //   font-size: 20px;
      //   font-weight: 500;
      //   text-align: center;
      //   // background-color: #fff;
      // }

      ::v-deep .el-tabs__item {
        // padding: 0;
        padding: 0 20px;
        min-width: 100px;
        height: 63px;
        line-height: 63px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        transition: .3s;
      }

      ::v-deep .el-tabs__item.is-active {
        background-color: #fff;
        color: #36AB64;
        font-weight: 500;
      }

      ::v-deep .el-tabs__active-bar {
        display: none;
      }

      ::v-deep .el-tabs__nav-wrap {
        &::after {
          content: '';
          display: none;
        }
      }

      .active {
        background-color: #fff;
        color: #36AB64;
        font-weight: 500;
      }
    }

    .head-total {
      padding: 0 30px 0 50px;
      color: #fff;
      border-left: 1px solid #e6e6e6;

      .num {
        margin: 0 12px;
        padding: 0 12px;
        vertical-align: middle;
        position: relative;
        top: -7px;
        background-color: #fff;
        color: #36AB64;
        font-family: Source Han Sans CN;
        font-size: 50px;
        font-weight: 500;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }

  .data-info {
    padding: 0 20px 0 30px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &-item {
      transition: .3s;
      display: flex;
      margin: 20px 10px 0 0;
      padding: 12px 15px;
      // width: 254px;
      flex: 0 0 calc((100% - 40px) / 4);
      height: 100px;
      border: #e6e6e6 1px solid;
      border-radius: 4px;
      box-sizing: border-box;

      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        cursor: pointer;
      }

      &:nth-child(4) {
        margin-right: 0px;
      }

      &-text {
        flex: 1;
        margin-top: 8px;
        color: #666;
        text-align: right;
        font-size: 18px;

        .item-num {
          margin-top: 12px;
          width: 100%;
          text-align: right;
          font-weight: bold;
          font-size: 38px;
          color: #333;
        }
      }

      &-unit {
        display: flex;
        align-items: flex-end;
        color: #666;
      }

      .active {
        color: #36AB64;
      }

      .icon {
        img {
          width: 70px;
        }
      }
    }
  }

  .search-container {
    margin: 30px 30px 0;
    // padding: 0 30px;
    width: 100%;
    border-radius: 2px;
    background: linear-gradient(180deg, #43D49C 3%, #14A874 99%);

    .search-people {
      display: flex;
      padding: 10px 15px 0;

      .search-form {
        display: flex;
        align-items: center;
        flex: 1;
        color: #fff;

        &-text {
          padding: 0 15px;
          min-width: 40px;
        }

        &-title {
          min-width: 80px;
        }
      }
    }

    .search-household {
      padding: 0px 15px 10px;
    }

    .search-btns {
      margin-left: 15px;
      margin-right: 2%;
      font-size: 18px;

      .rest-btn {
        margin-left: 20px;
        background: #F2F3F5 !important;
        color: #1D2129 !important;

        .el-icon {
          color: #1D2129 !important;
        }
      }

      .el-icon {
        margin-right: 8px;
      }

      ::v-deep .md-button .md-button-content {
        font-size: 16px;
      }
    }

    .search-household-input {
      margin-right: 24px;
    }

    .search-input {
      max-width: 150px;
      // height: 32px;

      // ::v-deep .el-input__inner {
      //   height: 32px;
      //   line-height: 32px;
      // }
    }
  }

  .menu {
    margin-top: 80px;
    padding-bottom: 80px;
    display: flex;
    width: 100%;

    &-item {
      flex: 1;
      font-family: PingFang SC;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      transition: .3s;

      &:hover {
        cursor: pointer;
        opacity: .7;
      }

      &-name {
        margin-left: 15px;
      }
    }
  }
}
</style>