<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-22 11:27:45
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 11:43:21
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\components\trendsForm\index.vue
 * @Description: 
-->
<template>
	<el-form :model="trendsForm" ref="ruleForm" label-width="110px" class="demo-ruleForm" :inline="true">
		<template v-for="item in formItems">
			<el-form-item v-if="item.key" :key="item.key" :label="item.label"
				:style="{ display: item.block ? 'block' : 'inline-block' }" :prop="item.key" :rules="item.rules">
				<el-input v-if="item.type === 'input'" v-model="trendsForm[item.key]"
					:placeholder="item.placeholder || '请输入'" :style="{ width: item.width }"></el-input>

				<el-input type="textarea" v-else-if="item.type === 'textarea'" :rows="3" v-model="trendsForm[item.key]"
					:placeholder="item.placeholder || '请输入'" :style="{ width: item.width }"></el-input>

				<el-select v-else-if="item.type === 'select'" v-model="trendsForm[item.key]"
					:placeholder="item.placeholder || '请选择'" :style="{ width: item.width }" :disabled="item.disabled">
					<el-option v-for="options in item.range" :key="options.value" :label="options.label"
						:value="options.value"></el-option>
				</el-select>

				<el-date-picker v-else-if="item.type === 'date'" type="date" placeholder="选择日期"
					v-model="trendsForm[item.key]" :style="{ width: item.width }"></el-date-picker>
			</el-form-item>
			<el-divider v-else-if="item.type === 'divider'"></el-divider>
		</template>
		<!-- <el-form-item label="活动区域" prop="region">
			<el-select v-model="ruleForm.region" placeholder="请选择活动区域">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
			</el-select>
		</el-form-item> -->
		<!-- <el-form-item label="活动时间" required>
			<el-col :span="11">
				<el-form-item prop="date1">
					<el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1"
						style="width: 100%;"></el-date-picker>
				</el-form-item>
			</el-col>
			<el-col class="line" :span="2">-</el-col>
			<el-col :span="11">
				<el-form-item prop="date2">
					<el-time-picker placeholder="选择时间" v-model="ruleForm.date2" style="width: 100%;"></el-time-picker>
				</el-form-item>
			</el-col>
		</el-form-item>
		<el-form-item label="即时配送" prop="delivery">
			<el-switch v-model="ruleForm.delivery"></el-switch>
		</el-form-item>
		<el-form-item label="活动性质" prop="type">
			<el-checkbox-group v-model="ruleForm.type">
				<el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
				<el-checkbox label="地推活动" name="type"></el-checkbox>
				<el-checkbox label="线下主题活动" name="type"></el-checkbox>
				<el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
			</el-checkbox-group>
		</el-form-item>
		<el-form-item label="特殊资源" prop="resource">
			<el-radio-group v-model="ruleForm.resource">
				<el-radio label="线上品牌商赞助"></el-radio>
				<el-radio label="线下场地免费"></el-radio>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="活动形式" prop="desc">
			<el-input type="textarea" v-model="ruleForm.desc"></el-input>
		</el-form-item> -->
		<!-- <div class="btns">
			<el-button type="success" @click="submitForm('ruleForm')">修 改</el-button>
		</div> -->
	</el-form>
</template>
<script>
export default {
	props: {
		formItems: {
			type: Array,
			default: () => []
		},
		trendsForm: {
			type: Object,
			default: () => Object.create({})
		}
	},
	data () {
		return {
			// ruleForm: {
			// 	name: ''
			// },
		};
	},
	created () {
		// this.formItems.forEach(item => {
		// 	this.ruleForm[item.key] = item.value
		// })
	},
	methods: {
		submitForm () {
			this.$refs.ruleForm.validate((valid) => {
				this.$emit('submit', valid)
				// if (valid) {
				// 	alert('submit!');
				// } else {
				// 	console.log('error submit!!');
				// 	return false;
				// }
			});
		},
		resetForm (formName) {
			this.$refs.ruleForm.resetFields();
		}
	}
}
</script>

<style lang="scss" scopde>
.btns {
	text-align: right;
}
</style>