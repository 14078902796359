export const formItems = [
	{
		label: '姓名',
		key: 'name',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请输入姓名', trigger: 'blur' },
			{ min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur' }
		]
	},
	{
		label: '身份证号码',
		key: 'idcard',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		// "^[1-9]\\d{5}(18|19|([23]\\d))\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$"
		rules: [
			// { required: true, message: '请输入身份证号码', trigger: 'blur' },
			{
				validator: (rule, value, callback) => {
					if (value == '') {
						return callback()
					}
					if (!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
						callback(new Error('身份证号码格式不正确'))
					}
					callback()
				}, trigger: 'blur'
			}
		]
	},
	{
		label: '性别',
		key: 'sex',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择性别', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			{
				label: '男',
				value: 1
			},
			{
				label: '女',
				value: 2
			},
			{
				label: '未知',
				value: 3
			}
		]
	},
	{
		label: '出生日期',
		key: 'birthDay',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择出生日期', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		]
	},
	{
		label: '民族',
		key: 'nation',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择民族', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		// 1汉族,2 少数民族 3外籍
		range: [
			{
				label: '汉族',
				value: 1
			},
			{
				label: '少数民族',
				value: 2
			},
			{
				label: '外籍',
				value: 3
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '出生地',
		key: 'nplace',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入出生地', trigger: 'blur' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			{
				label: '浙江省',
				value: 1
			}
		]
	},
	{
		label: '政治面貌',
		key: 'pstatus',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择政治面貌', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			// 1群众2团员3党员4民主党派5预备党员
			{
				label: '群众',
				value: 1
			},
			{
				label: '团员',
				value: 2
			},
			{
				label: '党员',
				value: 3
			},
			{
				label: '民主党派',
				value: 4
			},
			{
				label: '预备党员',
				value: 5
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '健康状况',
		key: 'health',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择健康状况', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			{
				label: '健康',
				value: 1
			},
			{
				label: '残疾',
				value: 2
			},
		]
		// 1健康2残疾
	},
	{
		label: '独生子女',
		key: 'isAlone',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择是否独生子女', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			{
				label: '是',
				value: 1
			},
			{
				label: '否',
				value: 2
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '婚姻状况',
		key: 'marryStatus',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择婚姻状况', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		// 1未婚2已婚3离异4丧偶5再婚
		range: [
			{
				label: '未婚',
				value: 1
			},
			{
				label: '已婚',
				value: 2
			},
			{
				label: '离异',
				value: 3
			},
			{
				label: '丧偶',
				value: 4
			},
			{
				label: '再婚',
				value: 5
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '文化情况',
		key: 'educate',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择文化情况', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		// 0未知1在读2文盲3小学4初中5高中6技工学校7中专8大专9本科10研究生11博士12留学生13其他
		range: (() => {
			const range = `在读 文盲 小学 初中 高中 技工学校 中专 大专 本科 研究生 博士 留学生 其他`.split(' ').map((v, i) => {
				return {
					label: v,
					value: i + 1
				}
			})
			range.push({
				label: '未知',
				value: 100
			})
			return range
		})()
	},
	{
		label: '兵役情况',
		key: 'byStatus',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择兵役情况', trigger: 'change' },
			// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		],
		range: [
			{
				label: '是',
				value: 1
			},
			{
				label: '不是',
				value: 2
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '手机号',
		key: 'phone',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		// const validatePhone = (rule, value, callback) => {
		// 	if (!value) {
		// 		callback(new Error('手机号不能为空'))
		// 	}
		// 	if (!/^1[35789]\d{9}$/.test(value)) {
		// 		callback(new Error('手机号格式不正确'))
		// 	}
		// 	callback()
		// }
		rules: [
			{
				validator: (rule, value, callback) => {
					if (value == '') {
						return callback()
					}
					if (!/^1[35789]\d{9}$/.test(value)) {
						callback(new Error('手机号格式不正确'))
					}
					callback()
				}, trigger: 'blur'
			}
		]
	},
	{
		label: '其他联系方式',
		key: 'otherLink',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入其他联系方式', trigger: 'blur' },
			{ min: 7, max: 12, message: '格式不正确', trigger: 'blur' }
		]
	},
	// {
	// 	label: 'QQ号',
	// 	key: 'qq',
	// 	type: 'input',
	// 	value: '',
	// 	width: '180px',
	// 	placeholder: '',
	// 	rules: [
	// 		{ required: true, message: '请输入QQ号', trigger: 'blur' },
	// 		{ min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
	// 	]
	// },
	{
		label: '车牌号',
		key: 'carNumber',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入车牌号', trigger: 'blur' },
			{ min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
		]
	},
	{
		label: '备注',
		key: 'remark',
		type: 'textarea',
		block: true,
		value: '',
		width: '480px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入备注', trigger: 'blur' },
			{ min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
		]
	},
	{
		label: '分割线',
		type: 'divider'
	},
	{
		label: '户主关系',
		key: 'relation',
		type: 'select',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: `户主 配偶 父母 子女 家属 亲属 朋友 租客 其他 房东`.split(' ').map((v, i) => {
			return {
				label: v,
				value: i + 1
			}
		})
	},
	{
		label: '',
		key: 'relationRemark',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '关系说明',
		rules: [
			// { required: true, message: '请输入关系说明', trigger: 'blur' },
		],
	},
	{
		label: '居住状态',
		key: 'lstatus',
		type: 'select',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: [
			{
				label: '暂住',
				value: 1
			},
			{
				label: '居住并入住',
				value: 2
			},
			{
				label: '居住未入住',
				value: 3
			},
			{
				label: '常住',
				value: 4
			},
			{
				label: '未入住',
				value: 5
			},
			{
				label: '未知',
				value: 100
			}
		]
	},
	{
		label: '户籍人员',
		key: 'isHuji',
		type: 'select',
		value: '',
		width: '250px',
		placeholder: '',
		disabled: true,
		rules: [
			// { required: true, message: '请选择', trigger: 'change' },
		],
		range: [
			{
				label: '是',
				value: 1
			},
			{
				label: '否',
				value: 2
			}
		]
	},
	// {
	// 	label: '迁入时间',
	// 	key: 'qrTime',
	// 	type: 'date',
	// 	value: '',
	// 	width: '250px',
	// 	placeholder: '',
	// 	rules: [
	// 		{ required: true, message: '请选择', trigger: 'change' },
	// 	],
	// },
	// {
	// 	label: '原户籍所在地',
	// 	key: 'yhjAddress',
	// 	type: 'input',
	// 	value: '',
	// 	width: '250px',
	// 	placeholder: '',
	// 	rules: [
	// 		{ required: true, message: '请输入', trigger: 'blur' },
	// 	],
	// },
	{
		label: '户籍地址',
		key: 'hjaddress',
		type: 'input',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入', trigger: 'blur' },
		],
	},
	{
		label: '居住地址',
		key: 'address',
		type: 'input',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入', trigger: 'blur' },
		],
	},
	{
		label: '分割线',
		type: 'divider'
	},
	{
		label: '就业状况',
		key: 'workStatus',
		type: 'select',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请选择', trigger: 'change' },
		],
		// 1单位招用2实际失业3 无就业能力4 企退5 非企退6 灵活就业 7 无就业愿望 8 未到法定年龄 9 在校读书 10 自谋职业 11其他形式就业
		range: (() => {
			const range = `单位招用 实际失业 无就业能力 企退 非企退 灵活就业 无就业愿望 未到法定年龄 在校读书 自谋职业 其他形式就业`.split(' ').map((v, i) => {
				return {
					label: v,
					value: i + 1
				}
			})
			range.push({
				label: '未知',
				value: 100
			})
			return range
		})()
	},
	{
		label: '职业',
		key: 'job',
		type: 'input',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入职业', trigger: 'blur' },
		],
	},
	{
		label: '工作单位',
		key: 'workUnit',
		type: 'input',
		value: '',
		width: '250px',
		placeholder: '',
		rules: [
			// { required: true, message: '请输入工作单位', trigger: 'blur' },
		],
	},
]

export const formTypeArr = [
	{
		name: '党员人口',
		key: '',
		value: [],
		range: []
		// range: `在职党员 在册党员 流动党员 党代表`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '帮扶优抚',
		key: '',
		value: [],
		range: []
		// range: `低保户 困难户(市级) 困难户(区级) 残疾人 残保户 失业人员 灵活就业人员 其他形式就业人员`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '计生人口',
		key: '',
		value: [],
		range: []
		// range: `育龄妇女 计生重点人员`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '老龄人口',
		key: '',
		value: [],
		range: []
		// range: `企退人员 空巢老人 独居老人 孤寡老人 老干部`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '居民代表',
		key: '',
		value: [],
		range: []
		// range: `楼道长 居民小组长 志愿者`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '流动人口',
		key: '',
		value: [],
		range: []
		// range: `暂住人员 租住人员 沿街商铺人员`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '综治人口',
		key: '',
		value: [],
		range: []
		// range: `社区矫正人员 邪教人员 刑释解散人员 精神病人员 吸毒人员 重点信访人员 危险品从业人员 境外重点人员 重点青少年人员`.split(' ').map((v, i) => {
		// 	return {
		// 		label: v,
		// 		value: i + 1
		// 	}
		// })
	},
	{
		name: '自定义标签',
		key: '',
		value: [],
		range: []
	},
]

export const domicileFormItems = [
	{
		label: '居住状态',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '户籍人员',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '迁出时间',
		key: 'carNumber',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '原户籍所在地',
		key: 'carNumber',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请输入原户籍所在地', trigger: 'blur' },
			{ min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
		]
	},
	{
		label: '户籍地址',
		key: 'carNumber',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请输入户籍地址', trigger: 'blur' },
			{ min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
		]
	},
	{
		label: '公安出生对应时间',
		key: 'carNumber',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
]

// 计生信息
export const familyPlanningFormItems = [
	{
		label: '结婚年月',
		key: 'carNumber',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '计生措施',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: []
	},
	{
		label: '最后一次月经',
		key: 'carNumber',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '是否独生子女',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: [
			{
				label: '是',
				value: 1
			},
			{
				label: '否',
				value: 2
			},
		]
	},
	{
		label: '预产期',
		key: 'carNumber',
		type: 'date',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		]
	},
	{
		label: '独生子女证',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: []
	},
	{
		label: '婚育证明编号',
		key: 'carNumber',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请输入婚育证明编号', trigger: 'blur' },
		],
		range: []
	},
	{
		label: '育龄卡号',
		key: 'carNumber',
		type: 'input',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请输入育龄卡号', trigger: 'blur' },
		],
	},
	{
		label: '独生子女证',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: []
	},
	{
		label: '独生子女证',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: []
	},
	{
		label: '独生子女证',
		key: 'carNumber',
		type: 'select',
		value: '',
		width: '180px',
		placeholder: '',
		rules: [
			{ required: true, message: '请选择', trigger: 'change' },
		],
		range: []
	},
]