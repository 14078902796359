/*
 * @Author: Huwenhua743
 * @Date: 2020-05-19 17:16:08
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-08 23:29:02
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\utils\http.js
 */

import axios from 'axios';
import Vue from 'vue'
import ElementUI from 'element-ui'
// import { notification } from 'antd';
// import { baseURL } from '@/constants';
// import { history } from 'umi';

// let gloabl_url = baseURL;
// if (PROJECT_ENV != 'local') {
//   gloabl_url = window.location.href.split('#')[0];
// }
// const instance = axios.create({ gloabl_url });
const instance = axios.create({
  baseURL: 'api',//process.env.BASE_URL || 'api',
  timeout: 600000,
})
// instance.defaults.headers.common.token = sessionStorage.token;

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers.token = sessionStorage.authorToken
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => {
    const { code, message } = res.data
    if (code !== 200) {
      ElementUI.Notification.error({
        message: `${message}`,
        title: '请求错误提示'
      })
    }
    if (code === 401) {
      sessionStorage.removeItem('authorToken')
      window.location.href = '/'
    }
    // const {
    //   headers: { authorization },
    //   data: { code, data },
    // } = res;

    // 如果后台返回新的token 说明当前token已失效 使用新的token
    // if (authorization) {
    //   instance.defaults.headers.common.Authorization = authorization;
    //   sessionStorage.token = authorization;
    // }

    // 登录超时
    // if (code === 'ERROR:4001') {
    //   sessionStorage.removeItem('token');
    //   history.push({
    //     pathname: '/login',
    //     query: {
    //       code: history.location.query.code,
    //     },
    //   });
    //   return {};
    // }
    // const [status, msg] = String(code).split(':');
    // const success = status.startsWith('SUCCESS');
    // if (!success) {
    //   const message = msg || '系统异常';
    //   if (message.includes('项目code不存在')) {
    //     history.replace({
    //       pathname: '/403',
    //     });
    //   } else {
    //     notification.error({ message });
    //   }
    // }

    return res.data || {};
  },
  err => {
    // if (err.response) {
    const { status } = err && err.response;
    const message = codeMessage[status] || '系统异常，请稍后重试'
    //   // if (status == 500 || status == 404 || status == 504) {
    //   //   history.push({
    //   //     pathname: '/404',
    //   //   });
    //   // } else {
    //   Vue.$notify({
    //     message:
    //       `${err.message || '系统异常，请稍后重试'}`,
    //     icon: "add_alert",
    //     horizontalAlign: 'center',
    //     verticalAlign: 'top',
    //     type: 'warning',
    //   })
    //   // }

    // } else {
    //   // notification.error({ message: err.message });
    // }
    if (err.response.data && err.response.data.code === 401) {
      sessionStorage.removeItem('authorToken')
      window.location.href = '/'
    }
    ElementUI.Notification.error({
      message:
        `${message}`,
      title: '服务异常提示'
    })
    return Promise.reject(err);
  },
);

export default instance;
