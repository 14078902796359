/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-20 14:49:02
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\routes\routes.js
 * @Description: 
 */
import VueRouter from 'vue-router';
import store from '@/store'
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import householdManage from "@/pages/household/index.vue"

const routes = [
  {
    path: '/login',
    component: () => import('@/pages/login/index.vue'),
    meta: {
      name: '登录'
    }
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/home",
    children: [
      {
        path: "userAdmin",
        name: "用户管理",
        component: () => import('@/pages/userAdmin/index.vue'),
      },
      {
        path: "household",
        name: "户信息管理",
        component: householdManage,
      },
      {
        path: "householdList",
        name: "户管理",
        component: () => import('@/pages/household/list.vue'),
      },
      {
        path: "home",
        name: "人口总览",
        component: Dashboard,
      },
      {
        path: "serviceProfile",
        name: "服务档案",
        component: () => import('@/pages/serviceProfile/index.vue'),
      },
      {
        path: "peopleResult",
        name: "人员管理",
        component: () => import('@/pages/peopleResult/index.vue'),
      },
      {
        path: "tags",
        name: "标签管理",
        component: () => import('@/pages/tags/index.vue'),
      },
      {
        path: "communityManagement",
        name: "小区管理",
        component: () => import('@/pages/communityManagement/index.vue'),
      },
      {
        path: "role",
        name: "角色管理",
        component: () => import('@/pages/roleList/index.vue'),
      },
      {
        path: "systemLog",
        name: "系统日志",
        component: () => import('@/pages/systemLog/index.vue'),
      },
      {
        path: "gridManage",
        name: "网格管理",
        component: () => import('@/pages/gridManage/index.vue'),
      },
      {
        path: "table",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      },
    ],
  },
];

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = store.getters.getToken
  if ((token && to.path !== '/login') || (!token && to.path === '/login')) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router;
