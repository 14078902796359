/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-21 17:32:06
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-03 23:46:30
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\communityManagement.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const addCommunity = (params) => {
	return Http({
		url: '/population/communityAreaAdd',
		data: params,
		method: 'post'
	})
}

export const getCommunity = () => {
	return Http({
		url: '/population/communityAreaList',
		method: 'get'
	})
}

export const updateCommunity = (params) => {
	return Http({
		url: `/population/communityAreaUpdate`,
		data: params,
		method: 'post'
	})
}
