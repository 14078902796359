/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-25 13:40:33
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-28 10:51:51
 * @FilePath: \projecte:\qingtian\shequ\src\api\peopleResult.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getHouseList = (params) => {
	return Http({
		url: '/population/houseList',
		params: params,
		method: 'get'
	})
}

export const getTags = () => {
	return Http({
		url: '/population/findTagsByType',
		params: { type: 1 },
		method: 'get'
	})
}

export const getAreaList = () => {
	return Http({
		url: '/population/communityAreaList',
		method: 'get'
	})
}

export const addHouse = (params) => {
	return Http({
		url: '/population/houseAdd',
		method: 'post',
		data: params
	})
}

export const updateHouse = (params) => {
	return Http({
		url: `/population/houseUpdate/${params.id}`,
		method: 'post',
		data: params
	})
}

export const delHouse = (params) => {
	return Http({
		url: `/population/houseDel/${params.id}`,
		method: 'get'
	})
}

export const getHouseInfo = (params) => {
	return Http({
		url: `/population/houseInfo/${params.id}`,
		method: 'get'
	})
}

export const getPeopleList = (params) => {
	return Http({
		url: '/population/populationList',
		method: 'get',
		params: params
	})
}
// admin/info