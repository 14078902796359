/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-14 16:30:16
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-05 17:59:14
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\home.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getHomeInfo = (params) => {
	return Http({
		url: '/population/index',
		method: 'get',
		params: params
	})
}
// /moveHouse