/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-14 16:25:12
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-16 14:45:02
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\userAdmin.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getUserList = (params) => {
	return Http({
		url: '/admin/list',
		params: params,
		method: 'get'
	})
}

/**
 * 获取工作条线
 * @returns 
 */
export const getWorkLimitList = () => {
	return Http({
		url: '/admin/workLimitList',
		method: 'get'
	})
}

export const updateUser = (params) => {
	return Http({
		url: '/admin/update',
		data: params,
		method: 'post'
	})
}

export const updateStatus = (params) => {
	return Http({
		url: `/admin/updateStatus/${params.id}`,
		params: params,
		method: 'post'
	})
}

export const delUser = (params) => {
	return Http({
		url: `/admin/delete/${params.id}`,
		// data: params,
		method: 'post'
	})
}

export const updateRole = (params) => {
	return Http({
		url: '/admin/role/update',
		data: params,
		method: 'post'
	})
}

export const addUser = (params) => {
	return Http({
		url: '/admin/register',
		data: params,
		method: 'post'
	})
}

export const getRoleList = () => {
	return Http({
		url: '/role/listAll',
		method: 'get'
	})
}

export const getUserRole = (params) => {
	return Http({
		url: `/admin/role/${params.id}`,
		method: 'get'
	})
}

export const getUserInfo = (params) => {
	return Http({
		url: `/admin/${params.id}`,
		method: 'get'
	})
}