/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-21 17:32:06
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-08 14:44:14
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\common.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getMenu = () => {
	return Http({
		url: '/admin/info',
		method: 'get'
	})
}

export const getMyInfo = () => {
	return Http({
		url: '/admin/myInfo',
		method: 'get'
	})
}

export const updatePas = (params) => {
	return Http({
		url: '/admin/updateSelfPassword',
		method: 'post',
		data: params
	})
}
// admin/info