<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-01 17:12:14
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-16 10:55:29
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\App.vue
 * @Description: 
-->
<template>
  <router-view id="app"></router-view>
</template>

<script>
export default {};
</script>
